import React, { useState, FC } from 'react';
import { css } from '@emotion/css';

const styles = {
  btn: css({
    border: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 45,
    height: 45,
    padding: '11px 3px',
    backgroundColor: 'transparent',
    position: 'relative',
    zIndex: 11,

    '&[aria-expanded="true"] span': {
      backgroundColor: '#444',

      '&:nth-of-type(1)': {
        transform: 'translateY(11px) rotate(-315deg)',
      },

      '&:nth-of-type(2)': {
        opacity: 0,
      },

      '&:nth-of-type(3)': {
        transform: 'translateY(-10px) rotate(315deg)',
      },
    },
  }),

  bar: css({
    height: 2,
    backgroundColor: '#fff',
    width: '100%',
    transition: '0.25s',
  }),

  nav: css({
    position: 'fixed',
    left: 0,
    width: '100%',
    top: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    height: '100%',
    transform: 'translateY(-100%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    transition: 'transform 0.5s ease-in-out',

    '&[aria-hidden="false"]': {
      transform: 'translateY(0)',
    },
  }),

  menu: css({

  }),

  item: css({
    textAlign: 'center',
    fontFamily: 'serif',
    fontWeight: 'bold',

    '&:not(:last-of-type)': {
      margin: '0 0 20px',
    },
  }),

  link: css({
    textDecoration: 'none',
    color: '#444',
    fontSize: '2rem',

    '&:hover': {
      textDecoration: 'underline',
    },
  }),
};

const HeaderNav: FC<{ path: string }> = ({ path }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <button className={styles.btn} type="button" onClick={handleOpen} aria-expanded={open} aria-controls="nav">
        <span className={styles.bar} />
        <span className={styles.bar} />
        <span className={styles.bar} />
      </button>
      <nav className={styles.nav} id="nav" aria-hidden={!open}>
        <ul className={styles.menu}>
          <li className={styles.item}>
            <a className={styles.link} href={`${path}./.`}>ホーム</a>
          </li>
          <li className={styles.item}>
            <a className={styles.link} href={`${path}newslist.html`}>お知らせ</a>
          </li>
          <li className={styles.item}>
            <a className={styles.link} href={`${path}menu/meal.html`}>お品書き</a>
          </li>
          <li className={styles.item}>
            <a className={styles.link} href={`${path}room.html`}>お部屋</a>
          </li>
          <li className={styles.item}>
            <a className={styles.link} href={`${path}about.html`}>割烹やましたについて</a>
          </li>
          <li className={styles.item}>
            <a className={styles.link} href={`${path}contact.html`}>ご予約・お問い合わせ</a>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default HeaderNav;
