import React, { useState, useRef, useEffect } from 'react';
import { css } from '@emotion/css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import type { VFC } from 'react';

const styles = {
  slide: css({

    '& img': {
      width: '100%',
    },
  }),

  navsWrap: css({
    maxWidth: 1400,
    margin: '0 auto',
    padding: '0 35px',

    '@media (min-width: 960px)': {
      padding: '0 70px',
    },
  }),

  navs: css({
    maxWidth: 1260,
    margin: '0 auto',
  }),

  nav: css({
    padding: '0 10px',
    cursor: 'pointer',
  }),

  navPic: css({
    paddingTop: '100%',
    position: 'relative',

    '& img': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: 'center center',
    },
  }),
};

const carousels = [
  'index-room-carousel-01.jpg',
  'index-room-carousel-02.jpg',
  'index-room-carousel-03.jpg',
  'index-room-carousel-04.jpg',
  'index-room-carousel-05.jpg',
  'index-room-carousel-06.jpg',
];

const RoomCarousel: VFC = () => {
  const [sliders, setSliders] = useState({
    slider: null,
    nav: null,
  });

  const slider = useRef(null);
  const nav = useRef(null);

  useEffect(() => {
    setSliders({
      slider: slider.current,
      nav: nav.current,
    });
  }, [slider, nav]);

  return (
    <>
      <div className="mb15">
        <Slider
          ref={slider}
          asNavFor={sliders.nav}
          arrows={false}
        >
          {
            carousels.map((carousel, index) => (
              <div className={styles.slide} key={index}>
                <img src={`images/${carousel}`} alt="" />
              </div>
            ))
          }
        </Slider>
      </div>
      <div className={styles.navsWrap}>
        <div className={styles.navs}>
          <Slider
            ref={nav}
            asNavFor={sliders.slider}
            slidesToShow={4}
            focusOnSelect
            responsive={[
              {
                breakpoint: 960,
                settings: {
                  slidesToShow: 3,
                },
              },
            ]}
          >
            {
              carousels.map((carousel, index) => (
                <div key={index}>
                  <div className={styles.nav}>
                    <div className={styles.navPic}>
                      <img src={`images/${carousel}`} alt="" />
                    </div>
                  </div>
                </div>
              ))
            }
          </Slider>
        </div>
      </div>
    </>
  );
};

export default RoomCarousel;
