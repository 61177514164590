import React from 'react';
import ReactDom from 'react-dom';

import HeaderCarousel from './components/HeaderCarousel';
import RoomCarousel from './components/RoomCarousel';

import './common.tsx';

ReactDom.render(<HeaderCarousel />, document.getElementById('headerCarousel'));
ReactDom.render(<RoomCarousel />, document.getElementById('roomCarousel'));
