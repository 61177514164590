import React, { useState, useRef, useEffect } from 'react';
import { css } from '@emotion/css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import type { VFC } from 'react';

const styles = {
  slide: css({

    '& img': {
      width: '100%',
    },
  }),
};

const carousels = [
  'index-header-carousel-04.jpg',
  'index-header-carousel-02.jpg',
  'index-header-carousel-03.jpg',
];

const HeaderCarousel: VFC = () => {
  return (
    <>
      <Slider
        slidesToShow={1}
        arrows={false}
        fade
        autoplay
        pauseOnHover={false}
      >
        {
          carousels.map((carousel, index) => (
            <div className={styles.slide} key={index}>
              <img src={`images/${carousel}`} alt="" />
            </div>
          ))
        }
      </Slider>
    </>
  );
};

export default HeaderCarousel;
